* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a, a:hover {
  text-decoration: none;
  color: inherit;
}

input,
label,
select,
button,
textarea
{
	margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;
}
