@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat/montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
//Fonts
$primary-font: montserratregular, sans-serif;
$primary-bold-font: montserratbold, sans-serif;
$primary-medium-font: montserratmedium, sans-serif;

//Color
$primary-color: #FECB00;
$secondary-color: #FBFBFB;
