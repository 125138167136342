@import "reset";
@import "variables";


/* ----------- GENERAL STYLES ------------ */
html, body { overflow-x: hidden; }
body {
  font-family: $primary-font;
  font-size: 18px;
  line-height: 2.5rem;
}
h1 {
  font-size: 56px;
  line-height: 68px;
  font-weight: bold;
  font-family: $primary-bold-font;
  color: #fff;

  @media(max-width: 767px){
    font-size: 38px;
    line-height: 40px;
  }
  @media(max-width: 575px){
    font-size: 30px;
    line-height: 32px;
  }
}
h2 {
  position: relative;
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 34px;

  @media(max-width: 767px){
    font-size: 26px;
    line-height: 28px;
  }
}
// h2::before, h4::before {
h2::before {
  content: url("../images/other/line.svg");
  position: absolute;
  top: -20px;
}
h4 {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 27px;
}
p, li:not(nav li) {
  font-size: 18px;
  line-height: 2rem;
}
p, a:not(nav a){
  line-height: 2rem;
  padding-bottom: 30px;
}
a.button, input.button {
  border: 2px dashed #DFDFDF;
  padding: 20px 45px;
  margin: 40px 0;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  font-family: $primary-bold-font;
  text-align: center;

  &:hover {
    background-color: $primary-color;
    border: 2px solid $primary-color;
  }
}

.container {
  @media (min-width: 1250px){
      min-width: 1208px;
  }
}


/* ----------- NAVIGATION ------------ */
header nav {
  background-color: rgba(0,0,0,.38);
  padding: 25px 1rem !important;
  -webkit-transition: padding .5s, background-color .5s;
  transition: padding .5s, background-color .5s;
  font-family: $primary-medium-font;

  @media (max-width: 575px){
    background-color: rgba(0,0,0,.8);
    padding: 25px 1rem !important;
  }
  .navbar-brand img {
    @media (max-width: 575px){
      max-width: 230px;
    }
  }
  ul {
    list-style-type: none;
    @media(max-width: 1199px){
      padding-top: 25px;
    }
    li {
      position: relative;
    }
    .media-item {
      margin-top: -5px;

      a {
         svg {
           @media(max-width: 1199px){
            width: 25px;
            height: 25px;
            margin-top: 10px;
          }
         }
         &:hover svg g {
           fill: #fff;
           opacity: 1;
         }
      }
      @media(min-width: 1199px){
        margin-left: 85px;
      }
    }
    a {
        color: white;
        font-size: 16px;
        line-height: 19px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    @media(max-width:  1199px){
      .media-item img {
        max-width: 25px;
        max-height: 25px;
        width: 25px;
      }
    }
  }
  .navbar-brand {
    img {
      width: 285px;
      height: auto;
    }
  }
  .media-item {
    position: relative;

    &:hover img {
      opacity: 1;

      path {
        fill: #fff;
      }
    }
  }
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
}
.nav-item:hover::before, .sticky .nav-item:hover::before, .nav-item.current::before {
  content: url("../images/other/line-short.svg");
  position: absolute;
  top: 0;
  left: 8px;

  @media (max-width: 1199px){
    & {
      top: 0px;
    }
  }
}
.sticky {
  nav {
    background-color: rgba(0,0,0,.8);
    padding: 0 1rem 10px 1rem !important;

    .nav-item:hover::before {
      @media(max-width: 1199px){
        display: none;
      }
    }
  }
  img {
    max-width: 250px;
  }
}



/* ----------- CONTENT ------------ */
.custom-padding-t {
  padding-top: 80px;
}
.custom-padding-b {
  padding-bottom: 80px;
}

/* Szachownica */
.box-text-left, .box-text-right  {
  padding: 30px 0;

  & > div {
    align-items: center;
  }
  img {
    width: 100%;
  }
}
.box-text-left > div:first-child {
  padding: 40px 30px 10px 15px;
}
.box-text-right > div:nth-child(2) {
  padding: 40px 15px 10px 30px;
}
.box-text-right > div:first-child {
  @media(max-width: 991px) {
    order: 2;
  }
}
.box-text-left > div img, .box-text-left > div img {
  @media(max-width: 991px) {
    margin: 0 auto;
  }
}
/* End Szachownica*/

.secondary-background {
  background-color: $secondary-color;
}
.text-gray {
  color: #B3B3B3;
}
a.button-color {
  background-color: $primary-color;
  display: inline-block;
  padding: 15px 55px;
  color: #000;
  margin-bottom: 80px;
  font-family: $primary-bold-font;
  text-align: center;

  @media(max-width: 575px){
    padding: 15px 25px;
  }

  &:hover {
    background-color: #E4B600;
  }
}
.custom-border-l {
  border-left: 2px solid $primary-color;
}


/* Icons section in content */
.icons-section {
  > div {
    padding: 0 40px;
    margin-top: 30px;
    margin-bottom: 60px;

    @media(max-width:767px){
      padding: 0 20px;
    }
  }
  img, p {
    display: block;
    margin: 0 auto;
  }
  img {
    max-width: 67px;
    height: 67px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  p {
    font-weight: bold;
    text-align: center;
    line-height: 28px;
  }
}


/* Lists */
section ul{
  list-style-image: url("../images/other/dot.svg");

  li {
    padding: 0 0 10px 10px;
    margin-left: 20px;
  }
}


/* Contact us section  */
section.contact-us {
  background-color: #000;

  @media(max-width: 991px){
      overflow: hidden;
  }

  @media (max-width: 768px){
    & .row > div:last-child {
      min-height: 350px;
    }
  }
  p {
    margin: 80px 35px 40px 0;
    font-size: 28px;
    line-height: 34px;
  }
  .figure {
    position: absolute;
    bottom: 0;
    left: 0;

    @media (max-width: 767px){
      right: 0;
    }
    @media (max-width: 767px){
      left: 20%;
    }
    @media (max-width: 500px){
      left: 15%;
    }
    @media (max-width: 450px){
      left: 10%;
    }
  }
  .col > div {
    height: 100%;
    width: 346px;
    position: relative;
    margin: 0 auto;
  }
  .icon {
    width: 30px;
    height: 30px;
    position: absolute;
  }
  .element1 {
    bottom: 30%;
    left: -20%;
    transform: rotate(20deg);

    @media (max-width: 767px){
      left: 0;
    }
  }
  .element2 {
    bottom: 75%;
    left: -10%;
    transform: rotate(-30deg);

    @media (max-width: 767px){
      left: 10%;
    }
  }
  .element3 {
    bottom: 70%;
    right: 5%;
    transform: rotate(20deg);

    @media (max-width: 991px){
      display: none;
    }
    @media (max-width: 767px){
      display: block;
      right: 10%;
    }
  }
  .element4 {
    bottom: 30%;
    right: 0;
    transform: rotate(-30deg);

    @media (max-width: 991px){
      display: none;
    }

    @media (max-width: 767px){
      display: block;
      right: 0;
    }
  }
}


/* Slider */
.slider {
  position: relative;
  background-size: cover;
  // max-height: 864px;
  height: 100vh;
  overflow: hidden;

  h1 {
    font-size: 61px;
    line-height: 73px;
    margin-bottom: 30px;

    @media(max-width: 767px){
      font-size: 38px;
      line-height: 40px;
    }
    @media(max-width: 575px){
      font-size: 30px;
      line-height: 32px;
    }
  }
  p {
      line-height: 1.8rem !important;
  }

  .carousel {
    height: 100%;

    .carousel-inner { //height: 100%; overflow: hidden;
      .carousel-item  {
        height: 100%;
        overflow: hidden;

        img {
          -webkit-filter: brightness(70%);
          filter: brightness(70%);
          object-fit: cover;

          @media(min-width: 1900px){
            width: 100%;
          }
        }

        .slider__text {
          position: absolute;
          top: 40%;

          @media(max-width: 767px){
              top: 30%;
          }
        }
      }
    }
    .carousel-indicators li {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin: 10px;
      padding-bottom: 10px !important;

      @media (max-width: 575px){
        width: 20px;
        height: 20px;
      }
    }
  }
}


/* Baner element */
.baner {
  position: relative;
  background-size: cover;
  // max-height: 510px;
  height: 50vh;
  overflow: hidden;


  img {
    -webkit-filter: brightness(70%);
     filter: brightness(70%);
     object-fit: cover;
    //  height: 100%;

     @media(min-width: 1900px){
       width: 100%;
     }
  }
  h1 {
    position: absolute;
    bottom: 25%;
  }
}
.icons img {
  padding-right: 20px;
}


/* Blog element */
.box-blog {
  margin: 0;

  > div {
    padding: 0;
    overflow: hidden;

    &:hover img {
      filter: brightness(85%);
      transform: scale(1.03);
    }
  }
  img {
    object-fit: cover;
    filter: brightness(70%);
    -webkit-transition-property: filter, transform;
    -webkit-transition-duration: 0.3s;
    transition-property: filter, transform;
    transition-duration: 0.3s;

    @media(min-width: 1900px){
      width: 100%;
    }
  }
  p {
    position: absolute;
    bottom: 25%;
    padding: 30px;
    font-size: 23px;
    line-height: 28px;
    color: #fff;
    font-family: $primary-bold-font;

    @media(max-width: 1199px){
      bottom: 40%;
    }
    span {
      background-color: #000;
      color: $primary-color;
      padding: 10px 5px;
      margin-right: 5px;
    }
  }
  h3 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    color: #fff;
    padding: 30px;
    width: 100%;

    span {
      font-size: 23px;
      line-height: 28px;
      padding-right: 20px;
    }
    a {
      padding: 10px 20px;
      background-color: $primary-color;
      color: #000;
      font-size: 16px;
      line-height: 26px;
      font-family: $primary-bold-font;

      &:hover {
          background-color: #E4B600;;
      }
    }
  }
}


/* Gallery element */
.gallery {
    margin: 0 !important;

  > div {
    min-height: 300px;
    max-height: 390px;

    &:hover {
      z-index: 2;
    }
    h3 {
      font-weight: bold;
      @media(max-width: 767px){
        width: 100%;
        margin: 0 auto;
        display: inline-block;
      }
    }
  }
}

/* Companies logo element */
.companies {
  img {
    filter: grayscale(100%);
    opacity: 0.5;
    -webkit-transition-property: filter, opacity;
    -webkit-transition-duration: 0.3s;
    transition-property: filter, opacity;
    transition-duration: 0.3s;
    margin: 0 auto;

    &:hover {
      filter: grayscale(0%);
      opacity: 1;
    }
  }
  .carousel-control-prev, .carousel-control-next {
    span {
      font-size: 30px;
    }
  }
  #logo-carousel {
    margin-bottom: 0;
  }
  .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.slick-slide {
    height: auto !important;
    display: inline-block !important;
    vertical-align: middle !important;
    float:none !important;
}
.slick-dots {
  display: none !important;
}

/* Fancybox */
.fancybox-custom .fancybox-skin {
  box-shadow: 0 0 50px #222;
}

/* Single post */
.form-newsletter input[type="email"]{
  color: #B3B3B3;
  border-bottom: 1px solid #B3B3B3;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #B3B3B3;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #B3B3B3;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #B3B3B3;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #B3B3B3;
  }
}

/* ----------- FOOTER ------------ */
footer {
  font-family: $primary-medium-font;

  p, a, li {
  font-size: 16px;
  line-height: 29px;
  }
  ul {
    list-style-type: none;
  }
  .footer__bottom  {
    .row > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-height: 50px;
    }
    span {
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
    }
    img, svg {
      margin-left: 30px;
    }
    .row > div:last-child img {
      margin-left: 0;
    }
    a:hover svg g {
         fill: #000;
         opacity: 1;
    }
  }
}
.footer__top, .contact {
  h4 {
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    padding-top: 15px;
  }
  a img {
    padding-top: 3px;
    padding-bottom: 8px;
  }
}
.footer__top h4::before {
  content: url("../images/other/line-short.svg");
  position: absolute;
  top: 0;
}
